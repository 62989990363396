import React from "react";
import PropTypes from "prop-types";
import { normalizedData } from "@utils";
import { graphql } from "gatsby";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-02";

const TerminosPage = ({ data }) => {
    const content = normalizedData(data?.homePage?.content || []);
    return (
        <Layout pageTitle="Home" className="white-version home-classic">
            <Header
                data={{
                    ...data.header,
                    ...data.navigation,
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="main-page-wrapper min-vh-100 terms-and-conditions">
                <h2>Términos y Condiciones de Uso</h2>

                <div>
                    <h4>INFORMACIÓN RELEVANTE</h4>
                    <p>
                        Es requisito necesario para la adquisición de los
                        productos que se ofrecen en este sitio, que lea y acepte
                        los siguientes Términos y Condiciones que a continuación
                        se redactan. El uso de nuestros servicios así como la
                        compra de nuestros productos implicará que usted ha
                        leído y aceptado los Términos y Condiciones de Uso en el
                        presente documento.
                    </p>
                </div>

                <div>
                    <h4>LICENCIA</h4>
                    <p>
                        Estradata a través de su sitio web concede una licencia
                        para que los usuarios utilicen los productos que son
                        exhibidos en este sitio web de acuerdo a los Términos y
                        Condiciones que se describen en este documento.
                    </p>
                </div>

                <div>
                    <h4>USO NO AUTORIZADO</h4>
                    <p>
                        En caso de que aplique (para venta de software,
                        templetes, u otro producto de diseño y programación)
                        usted no puede colocar uno de nuestros productos,
                        modificado o sin modificar, en un CD, sitio web o ningún
                        otro medio y ofrecerlos para la redistribución o la
                        reventa de ningún tipo.
                    </p>
                </div>

                <div>
                    <h4>PROPIEDAD</h4>
                    <p>
                        Usted no puede declarar propiedad intelectual o
                        exclusiva a ninguno de nuestros productos, modificado o
                        sin modificar. Todos los productos son propiedad de los
                        proveedores del contenido. En caso de que no se
                        especifique lo contrario, nuestros productos se
                        proporcionan sin ningún tipo de garantía, expresa o
                        implícita. En ningúna situación esta compañía será
                        responsable de ningún daño incluyendo, pero no limitado
                        a, daños directos, indirectos, especiales, fortuitos o
                        consecuentes u otras pérdidas resultantes del uso o de
                        la imposibilidad de utilizar nuestros productos.
                    </p>
                </div>

                <div>
                    <h4>PRIVACIDAD</h4>
                    <p>
                        Estradata.com garantiza que la información personal que
                        usted envía cuenta con la seguridad necesaria.
                    </p>

                    <p>
                        Estradata reserva los derechos de cambiar o de modificar
                        estos términos sin previo aviso.
                    </p>
                </div>
            </main>
            <Footer
                className="section-separator"
                data={{
                    ...data.footer,
                    socials: data.site.siteMetadata.socials,
                }}
            />
        </Layout>
    );
};

export const query = graphql`
    {
        site {
            ...Site
        }
        header: general(section: { eq: "header-5-white" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-1" }) {
            menu {
                ...Menu01
            }
        }
        footer: general(section: { eq: "footer-2-white" }) {
            ...Footer02
        }
        homePage(title: { eq: "terminos" }) {
            content {
                ...Content02
            }
        }
        allArticle(limit: 3) {
            nodes {
                ...Article
            }
        }
    }
`;

TerminosPage.propTypes = {
    data: PropTypes.object,
};

export default TerminosPage;
